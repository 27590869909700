import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'

import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAcU3EHRuc3vKdrmYz2F1z5m9BP57cIJIw',
  authDomain: 'button-master.firebaseapp.com',
  projectId: 'button-master',
  storageBucket: 'button-master.appspot.com',
  messagingSenderId: '911683644130',
  appId: '1:911683644130:web:d180261ff98d546bbc0cbb',
  measurementId: 'G-P01LCGLH2Q',
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig)
    if (process.env.NODE_ENV === 'development') {
      firebase.functions().useEmulator('localhost', 5001) //for firebase emulator local
      firebase.firestore().settings({
        host: 'localhost:8088',
        ssl: false,
      })
      firebase.auth().useEmulator('http://localhost:9099')
    }
    firebase.analytics()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('autoLogInUser', user)
      } else {
        this.$store.dispatch('autoLogOutUser')
      }
    })
  },
})
