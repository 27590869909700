

















































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  data() {
    return {
      // drawer: null,
      tab: null,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions']),
    items() {
      let items: {
        title: string
        icon: string
        route: string
        subitems?: { title: string; route: string }[]
      }[] = []
      items = [
        {
          title: 'Create a Button',
          icon: 'mdi-gesture-tap-button',
          route: '/',
        },
        {
          title: 'Saved Buttons',
          icon: 'mdi-content-save-outline',
          route: '/saved',
        },
        // { title: 'Reports', icon: 'mdi-chart-bar',
        //   subitems: [
        //     { title: 'Email Performance', route: 'reports-email-performance' },
        //     { title: 'Raised per Source', route: 'reports-raised-per-source'},
        //     { title: 'Raised per Category', route: 'reports-raised-per-category' },
        //   ]
        // },
      ]
      if (this.getUserPermissions && this.getUserPermissions.role === 'admin') {
        items.push({
          title: 'System',
          icon: 'mdi-laptop',
          route: 'system-admin',
        })
      }
      return items
    },
  },
  methods: {
    logOutClick() {
      // this.logOutUser() // executes on logout page
      this.$router.push('logout').catch((error) => {
        console.log(error)
      }) //catch error otherwise throws error in console; error is usually because user is already on the page
    },
  },
})
