import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import {
  UserData,
  PermissionData,
  ActionFeedback,
  ActionFeedbackResult,
  ActionFeedbackState,
  ButtonData,
} from '@/models/interfaces'
// import { CalendarTimestamp } from 'vuetify'

Vue.use(Vuex)

const dbUnsubscribes: (() => void)[] = [] // An array of functions that when called, unsub the data stream

export default new Vuex.Store({
  state: {
    user: null,
    userPermissions: null,
    users: [],
    usersPermissions: [],
    actionFeedback: {},
    workingButton: {},
    buttons: [],
    adminViewUserId: '',
  },
  getters: {
    getUser: (state) => {
      return state.user
    },
    getUserPermissions: (state) => {
      return state.userPermissions
    },
    getUsers: (state) => {
      return state.users
    },
    getUserById: (state) => (userId: string) => {
      return state.users.find((user: UserData) => user.id === userId)
    },
    getUsersPermissions: (state) => {
      return state.usersPermissions
    },
    getActionFeedback: (state) => {
      return state.actionFeedback
    },
    getWorkingButton: (state) => {
      return state.workingButton
    },
    getButtons: (state) => {
      return state.buttons
    },
    getAdminViewUserId: (state) => {
      return state.adminViewUserId
    },
  },
  mutations: {
    setUser: (state, userObj: UserData) => {
      Vue.set(state, 'user', userObj)
    },
    setUserPermissions: (state, userPermissionsObj: PermissionData) => {
      Vue.set(state, 'userPermissions', userPermissionsObj)
    },
    setUsers: (state, users: UserData[]) => {
      Vue.set(state, 'users', users)
    },
    setUsersPermissions: (state, permissions: PermissionData[]) => {
      Vue.set(state, 'usersPermissions', permissions)
    },
    setActionFeedback: (state, actionFeedbackObject: ActionFeedback) => {
      Vue.set(
        state.actionFeedback,
        actionFeedbackObject.type,
        actionFeedbackObject.result
      )
    },
    setWorkingButton: (state, workingButton) => {
      Vue.set(state, 'workingButton', workingButton)
    },
    setButtons: (state, buttons: ButtonData[]) => {
      Vue.set(state, 'buttons', buttons)
    },
    setAdminViewUserId: (state, adminViewUserId: string) => {
      Vue.set(state, 'adminViewUserId', adminViewUserId)
    },
  },
  actions: {
    logInUser: ({ commit }) => {
      const provider = new firebase.auth.GoogleAuthProvider()

      provider.setCustomParameters({
        prompt: 'select_account',
      })

      firebase
        .auth()
        .signInWithRedirect(provider)
        .then(() => {
          // Can pass "result" as variable via .then( (result) => {
          // The signed-in user info.
          // const user = result.user
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken
          // Log in functions handled in autoLogInUser via main.ts which watches for auth changes
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Success,
            messages: [error.code, error.message],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithGoogleAuth',
            result: result,
          })
        })
    },
    logInUserWithEmailLink: ({ commit }, email) => {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: process.env.VUE_APP_SITE_URL + '/login',
        // This must be true.
        handleCodeInApp: true,
      }
      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email)
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Success,
            messages: ['Check your email (' + email + ') for log-in link'],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithEmailLink',
            result: result,
          })
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.code, error.message],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithEmailLink',
            result: result,
          })
        })
    },
    autoLogInUser: async ({ commit, dispatch }, payload) => {
      //try to log in
      try {
        if (payload.emailVerified !== true) {
          throw 'Email not yet verified'
        }

        const user: UserData = {
          displayName: payload.displayName,
          photoURL: payload.photoURL,
          email: payload.email,
          lastLogin: firebase.firestore.Timestamp.now(),
        }

        user.id = payload.uid
        commit('setUser', user) // set here so that it may be used in bindings below

        // add or upadte user in db
        firebase
          .firestore()
          .collection('users')
          .doc(payload.uid)
          .set(user, { merge: true })
          .then(() => {
            console.log('User successfully merged')
          })
          .catch((error) => {
            console.error('Error merging user:', error)
          })

        // do a data load for the user
        dispatch('bindUserToDb')
        dispatch('bindUserPermissionsToDb')

        // data bindings go here...
        // currently no other data bindings needed
        //
      } catch (error) {
        console.log(error)
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Error,
          messages: [error.code, error.message],
        }
        commit('setActionFeedback', { type: 'autoLogInUser', result: result })
      }
    },
    logOutUser: () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          // Log out functions handled via main.ts which calls autoLogOutUser
        })
        .catch(function (error) {
          // An error happened.
          console.log('Error', error)
        })
    },
    autoLogOutUser: ({ commit }) => {
      dbUnsubscribes.forEach((unsubscribe) => {
        unsubscribe() // Calling the listener unsubscribes user from data stream
      })
      commit('setUser', null)
      commit('setUserPermissions', null)
      commit('setButtons', [])

      const result: ActionFeedbackResult = {
        state: ActionFeedbackState.Success,
        messages: ['You have successfully logged out'],
      }
      commit('setActionFeedback', { type: 'logOutUser', result: result })
    },
    updateUserPermission: async ({ getters, commit }, editedUserPayload) => {
      try {
        if (getters.getUserPermissions.role != 'admin') {
          throw 'Permission denied'
        }
        firebase
          .firestore()
          .collection('userPermissions')
          .doc(editedUserPayload.user.id)
          .set({ role: editedUserPayload.permissions.role }, { merge: true })
          .then(() => {
            const result: ActionFeedbackResult = {
              state: ActionFeedbackState.Success,
              messages: ['User permission updated'],
            }
            commit('setActionFeedback', {
              type: 'updateUserPermission',
              result: result,
            })
          })
          .catch((error) => {
            throw error
          })
      } catch (error) {
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Error,
          messages: [error],
        }
        commit('setActionFeedback', {
          type: 'updateUserPermission',
          result: result,
        })
      }
    },
    updateActionFeedback: ({ commit }, payload: ActionFeedback) => {
      commit('setActionFeedback', {
        type: payload.type,
        result: payload.result,
      })
    },
    resetActionFeedback: ({ commit }, payload: ActionFeedback) => {
      commit('setActionFeedback', { type: payload, result: null })
    },
    updateAdminViewUserId: ({ commit }, payload: string) => {
      commit('setAdminViewUserId', payload)
    },
    updateWorkingButton: ({ commit }, payload) => {
      commit('setWorkingButton', { ...payload }) // make a copy of the button
    },
    addButton: ({ getters, commit }, payload) => {
      try {
        //validation
        const errors = []
        if (!payload.button) {
          errors.push('Missing button specifications')
        }
        if (!getters.getUser) {
          errors.push('No user detected')
        }
        if (errors.length > 0) {
          throw errors
        }

        const newButtonObject = {
          button: payload.button,
          timestamp: firebase.firestore.Timestamp.now(),
          createdByUser: getters.getUser.id,
        }

        //update the db
        firebase
          .firestore()
          .collection('buttons')
          .add(newButtonObject)
          .then(() => {
            const result: ActionFeedbackResult = {
              state: ActionFeedbackState.Success,
              messages: ['Button saved'],
            }
            commit('setActionFeedback', { type: 'addButton', result: result })
          })
          .catch((error) => {
            throw error
          })
      } catch (error) {
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Error,
          messages: error,
        }
        commit('setActionFeedback', { type: 'addButton', result: result })
        return
      }
    },
    deleteButton: ({ getters, commit }, buttonId) => {
      try {
        //validation
        const errors = []
        if (!buttonId) {
          errors.push('Missing button id')
        }
        if (!getters.getUser) {
          errors.push('No user detected')
        }
        if (errors.length > 0) {
          throw errors
        }
        firebase
          .firestore()
          .collection('buttons')
          .doc(buttonId)
          .delete()
          .then(() => {
            const result: ActionFeedbackResult = {
              state: ActionFeedbackState.Success,
              messages: ['Button deleted'],
            }
            commit('setActionFeedback', {
              type: 'deleteButton',
              result: result,
            })
          })
          .catch(function (error) {
            throw error
          })
      } catch (error) {
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Error,
          messages: error,
        }
        commit('setActionFeedback', { type: 'deleteButton', result: result })
      }
    },
    bindUserToDb: ({ commit, getters }) => {
      const userId = getters.getUser.id
      try {
        if (!userId) {
          throw 'Unable to determine user Id'
        }
        const query = firebase.firestore().collection('users').doc(userId)
        const listener = query.onSnapshot((doc) => {
          commit('setUser', { id: doc.id, ...doc.data() })
          dbUnsubscribes.push(listener) // Add listener to unsubscribes for unsubscribing later (probably on logout)
        })
      } catch (error) {
        console.log(error)
      }
    },
    bindUserPermissionsToDb: ({ commit, getters }) => {
      const userId = getters.getUser.id
      console.log('getting permissinos...')
      try {
        if (!userId) {
          throw 'Unable to determine user Id'
        }
        const query = firebase
          .firestore()
          .collection('userPermissions')
          .doc(userId)
        const listener = query.onSnapshot((doc) => {
          commit('setUserPermissions', { id: doc.id, ...doc.data() })
          dbUnsubscribes.push(listener) // Add listener to unsubscribes for unsubscribing later (probably on logout)
        })
      } catch (error) {
        console.log(error)
      }
    },
    bindUsersToDb: ({ getters, commit }) => {
      try {
        if (
          getters.getUserPermissions &&
          getters.getUserPermissions.role != 'admin'
        ) {
          throw 'Permission denied'
        }
        const listener = firebase
          .firestore()
          .collection('users')
          .onSnapshot((querySnapshot) => {
            const items: UserData[] = []
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              const user: UserData = {
                id: doc.id,
                displayName: data.displayName,
                photoURL: data.photoURL,
                email: data.email,
                lastLogin: data.lastLogin,
              }
              items.push(user)
            })

            //now commit the items to Vuex state
            commit('setUsers', items)

            //add listener to unsubscribes for unsubscribing later (probably on logout)
            dbUnsubscribes.push(listener)
          })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
    bindUsersPermissionsToDb: ({ getters, commit }) => {
      try {
        if (
          getters.getUserPermissions &&
          getters.getUserPermissions.role != 'admin'
        ) {
          throw 'Permission denied'
        }
        const listener = firebase
          .firestore()
          .collection('userPermissions')
          .onSnapshot((querySnapshot) => {
            const items: PermissionData[] = []
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              const permission: PermissionData = {
                id: doc.id,
                role: data.role,
              }
              items.push(permission)
            })

            //now commit the items to Vuex state
            commit('setUsersPermissions', items)

            //add listener to unsubscribes for unsubscribing later (probably on logout)
            dbUnsubscribes.push(listener)
          })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
    bindButtonsToDb: ({ getters, commit }, payload) => {
      // determine which user's buttons we should see
      let userId = ''
      if (payload && getters.getUserPermissions.role === 'admin') {
        userId = payload
      } else {
        userId = getters.getUser.id
      }
      try {
        const listener = firebase
          .firestore()
          .collection('buttons')
          .where('createdByUser', '==', userId)
          .onSnapshot((querySnapshot) => {
            const items: ButtonData[] = []
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              const button: ButtonData = {
                id: doc.id,
                button: data.button,
                timestamp: data.timestamp,
                createdByUser: data.createdByUser,
              }
              items.push(button)
            })
            items.sort((a, b) => {
              return a['timestamp'] > b['timestamp'] ? 1 : -1
            })
            items.reverse()

            //now commit the items to Vuex state
            commit('setButtons', items)

            //add listener to unsubscribes for unsubscribing later (probably on logout)
            dbUnsubscribes.push(listener)
          })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
  },
  modules: {},
})
