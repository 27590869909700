import firebase from 'firebase/app'

export interface ButtonObject {
  sourceCode: string
  forEmail: boolean
  forOutlook: boolean
  forActBlue: boolean
  actBlue: {
    amounts: string
    text: string
    other: boolean
    otherText: string
    url: string
    expressLane: boolean
    expressLaneDisclaimer: string
    expressLaneDisclaimerStyle: string
  }
  buttonStack: { text: string; url: string }[]
  color: string
  color2: string
  gradient: boolean
  border: boolean
  borderColor: string
  borderWidth: string
  borderStyle: string
  fontSize: string
  height: string
  width: string
  radius: string
  spacing: string
  columns: string
  fontFamily: string
  bold: boolean
  center: boolean
}
export const enum ActionFeedbackState {
  Success = 'success',
  Error = 'error',
}
export interface ActionFeedbackResult {
  state: ActionFeedbackState
  messages: string[]
}
export interface ActionFeedback {
  type: string
  result: ActionFeedbackResult
}

// TypeScript interfaces for database
export interface UserData {
  id?: string
  displayName: string
  photoURL: string
  email: string
  lastLogin: firebase.firestore.Timestamp
}
export const enum PermissionRole {
  Admin = 'admin',
}
export interface PermissionData {
  id: string
  role: PermissionRole
}
export interface ButtonData {
  id?: string
  button: ButtonObject
  timestamp: firebase.firestore.Timestamp
  createdByUser: string
}
