import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`)
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'ButtonMaker',
    component: lazyLoad('ButtonMaker'),
  },
  {
    path: '/saved',
    name: 'SavedButtons',
    component: lazyLoad('SavedButtons'),
    meta: { requiresAuth: true },
  },
  {
    path: '/system-admin',
    name: 'SystemAdmin',
    component: lazyLoad('SystemAdmin'),
    meta: { requiresAuth: true, systemAdminOnly: true },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: lazyLoad('LoginPage'),
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: lazyLoad('LogoutPage'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth && !store.getters.getUser) {
    next({ name: 'LoginPage' })
  } else if (
    to.meta &&
    to.meta.systemAdminOnly &&
    store.getters.getUserPermissions &&
    store.getters.getUserPermissions.role !== 'admin'
  ) {
    next({ name: 'LoginPage' })
  } else next()
})

export default router
